import React, { Component } from 'react';
import { Container } from 'reactstrap';
import { GatsbyImage } from "gatsby-plugin-image";
import Slider from 'react-slick';

class ExclusiveRenewal4 extends Component {
    render() {
        const settings = {
            infinite: true,
            slidesToShow: 4,
            slidesToScroll: 1,
            dots: true,
            speed: 500,
            responsive: [
                {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: true,
                        arrows: false,
                    },
                },
            ],
        };

        return (
            <section className="exclusive-renewal-4">
                <Container>
                    <Slider {...settings}>
                        <div>
                            <GatsbyImage image={this.props.socialProof1} />
                            <GatsbyImage image={this.props.socialProof2} />
                        </div>
                        <div>
                            <GatsbyImage image={this.props.socialProof3} />
                            <GatsbyImage image={this.props.socialProof4} />
                        </div>
                        <div>
                            <GatsbyImage image={this.props.socialProof5} />
                            <GatsbyImage image={this.props.socialProof6} />
                        </div>
                        <div>
                            <GatsbyImage image={this.props.socialProof7} />
                            <GatsbyImage image={this.props.socialProof8} />
                        </div>
                        <div>
                            <GatsbyImage image={this.props.socialProof9} />
                            <GatsbyImage image={this.props.socialProof10} />
                        </div>
                        <div>
                            <GatsbyImage image={this.props.socialProof11} />
                            <GatsbyImage image={this.props.socialProof12} />
                        </div>
                    </Slider>
                </Container>
            </section>
        );
    }
}

export default ExclusiveRenewal4;
