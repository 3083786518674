import React, { Component } from 'react';
import { Container } from 'reactstrap';
import star from '../../images/exclusive-renewal/star.svg';
import Slider from 'react-slick';
import {USER_COUNT} from '../../constants';

class ExclusiveRenewal5 extends Component {
    render() {
        const settings = {
            infinite: true,
            slidesToShow: 3,
            slidesToScroll: 1,
            dots: true,
            speed: 500,
            responsive: [
                {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: true,
                        arrows: false,
                    },
                },
            ],
        };

        return (
            <section className="exclusive-renewal-5">
                <Container>
                    <h2>{USER_COUNT}+ użytkowników Diet & Training by Ann</h2>
                    <Slider {...settings}>
                        <div className="panel">
                            <img src={star} className="star" alt="" />
                            <img src={star} className="star" alt="" />
                            <img src={star} className="star" alt="" />
                            <img src={star} className="star" alt="" />
                            <img src={star} className="star" alt="" />
                            <p>
                                Ja jestem bardzo zadowolona! Osobiste sympatie
                                lub jej brak do Pani Lewandowskiej nie mają tu
                                nic do rzeczy. Liczy się sport…
                            </p>
                            <span className="sign">- Magdalena</span>
                        </div>

                        <div className="panel">
                            <img src={star} className="star" alt="" />
                            <img src={star} className="star" alt="" />
                            <img src={star} className="star" alt="" />
                            <img src={star} className="star" alt="" />
                            <img src={star} className="star" alt="" />
                            <p>
                                Aplikacja prosta w użyciu. Bardzo pomocna lista
                                zakupów (można iść do sklepu z telefonem i
                                odhaczać po kolei co już mamy w koszyku)…
                            </p>
                            <span className="sign">- Szopeczka21</span>
                        </div>

                        <div className="panel">
                            <img src={star} className="star" alt="" />
                            <img src={star} className="star" alt="" />
                            <img src={star} className="star" alt="" />
                            <img src={star} className="star" alt="" />
                            <img src={star} className="star" alt="" />
                            <p>
                                Rewelacja! Proste przepyszne przepisy, zrzucanie
                                zbędnych kilogramów to sama przyjemność
                            </p>
                            <span className="sign">- Barbara</span>
                        </div>

                        <div className="panel">
                            <img src={star} className="star" alt="" />
                            <img src={star} className="star" alt="" />
                            <img src={star} className="star" alt="" />
                            <img src={star} className="star" alt="" />
                            <img src={star} className="star" alt="" />
                            <p>
                                Proste i smaczne przepisy. Zdrowe i nie
                                pracochłonne. Jestem bardzo zadowolona. Dziękuję
                            </p>
                            <span className="sign">- Ewelina</span>
                        </div>
                    </Slider>
                </Container>
            </section>
        );
    }
}

export default ExclusiveRenewal5;
