import React, { Component } from "react";
import { SaleProductConsumer } from "../../SaleProduct/SaleProductContext";
import SaleProductPlaceholderSection from "./SaleProductsPlaceholder";
import { Row, Col } from 'reactstrap';
import { addToCartLink } from "../../SaleProduct/CreateAddToCartLink";

export default class SaleProductPromoBox extends Component {
    render() {
        return (
            <SaleProductConsumer>
                {
                    ({ saleProducts, isLoading, promoCode, source }) =>
                    isLoading ? (
                        <>
                            <SaleProductPlaceholderSection />
                        </>
                    ) : (
                        saleProducts.map(saleProduct => (
                            <div className="promo-box" key={saleProduct.id}>
                                <Row>
                                    <Col md="5">
                                        <h3>{saleProduct.shortDescription}</h3>
                                        <p>
                                            {saleProduct.longDescription}
                                        </p>
                                    </Col>
                                    <Col md="3" className="my-4 my-md-0">
                                        <p className="blue">
                                            {saleProduct.productCardTitle}
                                            <br />
                                            {
                                                saleProduct.hasActivePromo && this.props.showOldPrice ? (
                                                    <React.Fragment>
                                                        <span
                                                            data-test="product-price"
                                                            className="product-price big"
                                                        >
                                                            {`${saleProduct.price.toString()}`}
                                                        </span>
                                                        <br/>
                                                        <span
                                                            className="old-price"
                                                        >
                                                            {`${saleProduct.regularPrice.toString()}`}
                                                        </span>
                                                    </React.Fragment>
                                                ) : (
                                                    <span
                                                        data-test="product-price"
                                                        className="product-price big"
                                                    >
                                                        {`${saleProduct.price.toString()}`}
                                                    </span>
                                                )
                                            }
                                            <br />
                                            {saleProduct.lengthLabel}
                                        </p>
                                    </Col>
                                    <Col md="4" className="text-center">
                                        <h3>Oferta dla Ciebie</h3>
                                        <a
                                            data-testid="add-to-cart"
                                            href={addToCartLink(saleProduct.id, promoCode, source)}
                                            className={`btn btn-${this.props.color} btn-auto-width`}
                                        >
                                            Kup teraz
                                        </a>
                                    </Col>
                                </Row>
                            </div>
                        ))               
                    )
                }
            </SaleProductConsumer>
        );
    }
}
