import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';

import clock from '../../images/exclusive-renewal/clock.svg';
import cloud from '../../images/exclusive-renewal/cloud.svg';
import exchange from '../../images/exclusive-renewal/exchange.svg';
import heart from '../../images/exclusive-renewal/heart.svg';
import phone from '../../images/icons/phone.svg';
import shoppingList from '../../images/exclusive-renewal/shopping-list.svg';
import support from '../../images/exclusive-renewal/support.svg';

import {TRAINING_PROGRAMS, TRAINING_DAYS, CALENDAR_ICON} from '../../constants';

class ExclusiveRenewal3 extends Component {
    render() {
        return (
            <section className="exclusive-renewal-3">
                <Container>
                    <h2>Przypomnij sobie do czego wrócisz</h2>
                    <Row>
                        <Col className="col-12 col-md-3 d-flex d-md-block text-center">
                            <img src={CALENDAR_ICON} className="icon" alt="" />
                            <p>Ponad {TRAINING_DAYS} dni treningowych</p>
                        </Col>
                        <Col className="col-12 col-md-3 d-flex d-md-block text-center">
                            <img src={cloud} className="icon" alt="" />
                            <p>Dostęp offline</p>
                        </Col>
                        <Col className="col-12 col-md-3 d-flex d-md-block text-center">
                            <img src={phone} className="icon" alt="" />
                            <p>{TRAINING_PROGRAMS} programów</p>
                        </Col>
                        <Col className="col-12 col-md-3 d-flex d-md-block text-center">
                            <img src={heart} className="icon" alt="" />
                            <p>Zbilansowany plan diety</p>
                        </Col>
                    </Row>

                    <Row>
                        <Col className="col-12 col-md-3 d-flex d-md-block text-center">
                            <img src={support} className="icon" alt="" />
                            <p>Wsparcie dietetyków online</p>
                        </Col>
                        <Col className="col-12 col-md-3 d-flex d-md-block text-center">
                            <img src={exchange} className="icon" alt="" />
                            <p>Wymiana jadłospisów i produktów</p>
                        </Col>
                        <Col className="col-12 col-md-3 d-flex d-md-block text-center">
                            <img src={clock} className="icon" alt="" />
                            <p>Pomiary i śledzenie postępów</p>
                        </Col>
                        <Col className="col-12 col-md-3 d-flex d-md-block text-center">
                            <img src={shoppingList} className="icon" alt="" />
                            <p>Interaktywna lista zakupów</p>
                        </Col>
                    </Row>
                </Container>
            </section>
        );
    }
}

export default ExclusiveRenewal3;
