import React, { Component } from 'react';
import { Container} from 'reactstrap';

import SaleProductPromoBox from "../SaleProduct/SaleProductPromoBox";
import SaleProductProvider from "../../SaleProduct/SaleProductContext";

class ExclusiveRenewal2 extends Component {
    render() {
        return (
            <section className="exclusive-renewal-2">
                <Container>
                    <SaleProductProvider tag='landing:exclusive-renewal' source='exclusive-renewal'>
                        <SaleProductPromoBox color="blue" showOldPrice={false} />
                    </SaleProductProvider>
                </Container>
            </section>
        );
    }
}

export default ExclusiveRenewal2;
