import React, { Component } from 'react';
import { Container } from 'reactstrap';
import BackgroundImage from 'gatsby-background-image';
import { convertToBgImage } from "gbimage-bridge"
import SaleProductPromoBox from "../SaleProduct/SaleProductPromoBox";
import SaleProductProvider from "../../SaleProduct/SaleProductContext";

class ExclusiveRenewal6 extends Component {
    render() {
        const bgImage = convertToBgImage(this.props.bgIndividual) 
        return (
            <BackgroundImage
                Tag="section"
                className="exclusive-renewal-6"                    
                {...bgImage}
            >
                <div className="half-white" />
                <Container id="buy">
                    <h2>Oferta ograniczona czasowo!</h2>
                    <p>
                        Zdrowy i szczęśliwy styl życia kształtowany jest
                        dzięki odpowiedniej diecie, treningowi i codziennej
                        dawce najlepszej motywacji. Wróć do niego jeszcze
                        dziś!
                    </p>

                    <SaleProductProvider tag='landing:exclusive-renewal' source='exclusive-renewal'>
                        <SaleProductPromoBox color="white" showOldPrice={true} />
                    </SaleProductProvider>

                </Container>
            </BackgroundImage>
        );
    }
}

export default ExclusiveRenewal6;
