import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { GatsbyImage } from "gatsby-plugin-image";

const Hero = (props) => (
    <section className="hero">
        <GatsbyImage
            image={props.hero}
            style={{position: "absolute", left: 0, top: 0, width: "100%", height: "100%"}} />
        <Container>
            <Row>
                <Col>
                    <h1>Limitowana półroczna oferta dostępu!</h1>
                    <p>Diet & Training by Ann czeka na Ciebie!</p>
                </Col>
            </Row>
        </Container>
    </section>
);
    
export default Hero;
