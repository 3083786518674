import React from "react";
import '../helpers/suppress-install-prompt';
import Seo from "../components/seo";
import Layout from "../components/Layout";
import Hero from "../components/ExclusiveRenewal/Hero";
import ExclusiveRenewal1 from "../components/ExclusiveRenewal/ExclusiveRenewal1";
import ExclusiveRenewal2 from "../components/ExclusiveRenewal/ExclusiveRenewal2";
import ExclusiveRenewal3 from "../components/ExclusiveRenewal/ExclusiveRenewal3";
import ExclusiveRenewal4 from "../components/ExclusiveRenewal/ExclusiveRenewal4";
import ExclusiveRenewal5 from "../components/ExclusiveRenewal/ExclusiveRenewal5";
import ExclusiveRenewal6 from "../components/ExclusiveRenewal/ExclusiveRenewal6";
import { GatsbyImage } from "gatsby-plugin-image";
import { Container } from 'reactstrap';

import { graphql } from "gatsby";

const Page = ({ data }) => (
    <Layout page="exclusive-renewal" showMenu={true}>
        <Seo
          description="Nie bój się wyzwań. Dzień, który właśnie mija, nie wróci. Dlatego już dziś wróć na najlepszą ścieżkę tak, jak prawie 500 000 użytkowników Diet & Training by Ann"
        />
        <Hero hero={data.hero.childImageSharp.gatsbyImageData} />
        <ExclusiveRenewal1 />
        <ExclusiveRenewal2 />
        <ExclusiveRenewal3 />
        <ExclusiveRenewal4
            socialProof1={data.socialProof1.childImageSharp.gatsbyImageData}
            socialProof2={data.socialProof2.childImageSharp.gatsbyImageData}
            socialProof3={data.socialProof3.childImageSharp.gatsbyImageData}
            socialProof4={data.socialProof4.childImageSharp.gatsbyImageData}
            socialProof5={data.socialProof5.childImageSharp.gatsbyImageData}
            socialProof6={data.socialProof6.childImageSharp.gatsbyImageData}
            socialProof7={data.socialProof7.childImageSharp.gatsbyImageData}
            socialProof8={data.socialProof8.childImageSharp.gatsbyImageData}
            socialProof9={data.socialProof9.childImageSharp.gatsbyImageData}
            socialProof10={data.socialProof10.childImageSharp.gatsbyImageData}
            socialProof11={data.socialProof11.childImageSharp.gatsbyImageData}
            socialProof12={data.socialProof12.childImageSharp.gatsbyImageData}
        />
        <ExclusiveRenewal5 />
        <section className="exclusive-renewal-5 pt-0">
            <Container>
                <GatsbyImage image={data.collage.childImageSharp.gatsbyImageData} />
            </Container>
        </section>
        <ExclusiveRenewal6
            bgIndividual={data.bgIndividual.childImageSharp.gatsbyImageData}
        />
    </Layout>
);

export default Page;

export const query = graphql`
    query {
        hero: file(relativePath: { eq: "exclusive-renewal/header.jpg" }) {
            ...fluidImage1920
        }

        collage: file(relativePath: { eq: "exclusive-renewal/collage.png" }) {
            ...fluidImage1920
        }

        frame: file(relativePath: { eq: "exclusive-renewal/phone-frame.png" }) {
            ...fluidImage480
        }

        socialProof1: file(
            relativePath: {
                eq: "exclusive-renewal/social-proof/social-proof-1.jpg"
            }
        ) {
            ...fluidImage480
        }

        socialProof2: file(
            relativePath: {
                eq: "exclusive-renewal/social-proof/social-proof-2.jpg"
            }
        ) {
            ...fluidImage480
        }

        socialProof3: file(
            relativePath: {
                eq: "exclusive-renewal/social-proof/social-proof-3.jpg"
            }
        ) {
            ...fluidImage480
        }

        socialProof4: file(
            relativePath: {
                eq: "exclusive-renewal/social-proof/social-proof-4.jpg"
            }
        ) {
            ...fluidImage480
        }

        socialProof5: file(
            relativePath: {
                eq: "exclusive-renewal/social-proof/social-proof-5.jpg"
            }
        ) {
            ...fluidImage480
        }

        socialProof6: file(
            relativePath: {
                eq: "exclusive-renewal/social-proof/social-proof-6.jpg"
            }
        ) {
            ...fluidImage480
        }

        socialProof7: file(
            relativePath: {
                eq: "exclusive-renewal/social-proof/social-proof-7.jpg"
            }
        ) {
            ...fluidImage480
        }

        socialProof8: file(
            relativePath: {
                eq: "exclusive-renewal/social-proof/social-proof-8.jpg"
            }
        ) {
            ...fluidImage480
        }

        socialProof9: file(
            relativePath: {
                eq: "exclusive-renewal/social-proof/social-proof-9.jpg"
            }
        ) {
            ...fluidImage480
        }

        socialProof10: file(
            relativePath: {
                eq: "exclusive-renewal/social-proof/social-proof-10.jpg"
            }
        ) {
            ...fluidImage480
        }

        socialProof11: file(
            relativePath: {
                eq: "exclusive-renewal/social-proof/social-proof-11.jpg"
            }
        ) {
            ...fluidImage480
        }

        socialProof12: file(
            relativePath: {
                eq: "exclusive-renewal/social-proof/social-proof-12.jpg"
            }
        ) {
            ...fluidImage480
        }

        bgIndividual: file(
            relativePath: { eq: "exclusive-renewal/bg-individual.jpg" }
        ) {
            ...fluidImage1920
        }
    }
`;
