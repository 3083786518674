import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';

import phoneFrame from '../../images/exclusive-renewal/phone-frame.png';
import video from '../../video/exclusive-renewal-316x684-pl.mp4';

class ExclusiveRenewal1 extends Component {
    render() {
        return (
            <section className="exclusive-renewal-1">
                <div className="half-white">
                    <Container>
                        <Row>
                            <Col md="6" className="pt-5">
                                <h2>Nie bój się wyzwań!</h2>
                                <p>
                                    Nie bój się wyzwań. Dzień, który właśnie
                                    mija, nie wróci. Dlatego już dziś wróć na
                                    najlepszą ścieżkę! Tylko Ty decydujesz.
                                </p>
                                <br />
                                <br />
                                <h2>Zrealizuj swój cel!</h2>
                                <p>
                                    Wróć na drogę ku wymarzonej sylwetce na
                                    wyjątkowych, ekskluzywnych warunkach. Teraz
                                    nie będziesz mieć już wymówki i osiągniesz
                                    sukces.
                                </p>
                            </Col>
                            <Col md="6" className="text-center">
                                <div className="video-container">
                                    <img
                                        src={phoneFrame}
                                        className="img-fluid phone-frame"
                                        alt=""
                                    />
                                    <video
                                        className="video"
                                        aria-hidden="true"
                                        muted
                                        autoPlay
                                        playsInline
                                        loop
                                    >
                                        <source src={video} type="video/mp4" />
                                    </video>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </section>
        );
    }
}

export default ExclusiveRenewal1;
